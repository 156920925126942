import React from "react"
import Layout from "../../components/layout/Layout"
import "./CambiosDevoluciones.css"

export default () => {
  return (
    <Layout type="HMNF" title="Cambios y Devoluciones">
      <div className="returns-content">
        <h2>CAMBIOS Y DEVOLUCIONES</h2>
        <p>Falta texto</p>
      </div>
    </Layout>
  )
}
